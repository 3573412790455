import React from 'react'

export default function Footer() {
  return (
   <footer>

        <section className='footerSec-1'>
          <section className='Footer_About'>
            <h3>Amore Mebel 15 il Xidmetinizdə
             Azərbaycanda nömrə 1. Güvən və 15 illik Təcrübə
             Mebelə aid her şeyi bizdən soruşun. Sizə özəl mebelər
            </h3>
            <div className="vertical-line"></div>

          </section>
          <section className='Social_media_icons'>
            <a className="fa-brands fa-instagram fa-beat text-light" href="https://www.instagram.com/amoremebel.az/" target="_blank" rel="noopener noreferrer" >
            </a>

            <a className="fa-solid fa-comment-dots fa-bounce text-light" href="sms:+994777309060?body=Salam!" target="_blank" rel="noopener noreferrer" >
            </a>

            <a className="fa-solid fa-square-phone fa-shake text-light" href="tel:+994777309060" target="_blank" rel="noopener noreferrer" >
            </a>

          </section>
        </section>
        <section className='footerSec-2 bg-dark'>
            <a className='ByAuthor'  href="https://www.linkedin.com/in/nihad-axundzade-760296240/" target="_blank" rel="noopener noreferrer">By Nihad</a>
            <p className='text-light'>&copy; 2023/2024 Amore Mebel. All rights reserved.</p>
        </section>
   </footer>
  )
}
