import './App.css';
import Footer from './Footer';

import Header from './Header';
import MainPage from './MainPage';



function App() {
 

  return (
    <div>
      <div className="whatsapp-container">
        <a href="https://api.whatsapp.com/send?phone=994777309060&text=Salam!" target="_blank" rel="noopener noreferrer" className="whatsapp-button fa-brands fa-whatsapp fa-beat-fade">
        </a>
      </div>
      <Header></Header>
      <MainPage></MainPage>
      <div className="d-flex bg-dark line_and_iconDiv">
          <div className="div1"></div>
            <div className='FontAwsomeSofaIconDiv animate__animated animate__heartBeat'  style={{ color:'white' ,margin: '0.5vi', fontSize: '3vh' }}>
              <i className="fa-sharp fa-solid fa-couch fa-fade"></i>
            </div>
          <div className="div1"></div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
