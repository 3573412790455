import React, { useEffect, useState } from 'react';
import Sliders from './Sliders';
import Body from './Body';

export default function MainPage() {
  const [showSliders, setShowSliders] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSliders(true);
    }, 950);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <section className='HeaderBottom'></section>
      {showSliders && <Sliders />}
      <Body />
    </>
  );
}
