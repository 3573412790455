import React, { useEffect, useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators
} from 'reactstrap';





function Slider({list, isok = true}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (!animating) {
        const nextIndex = activeIndex === list.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [activeIndex, animating, list.length]);

  if (list == null || list.length === 0) {
    return null;
  }

  if(list==null||list.length===0){
    return;

  }
  const slides = list.map((item) => {
    if(isok){
      return (
        <CarouselItem className='CarouselItem'
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={item.src}>
          <img src={item.src} alt={item.altText}/>
          
        </CarouselItem>
      );
    }
    else{
      return (
        <CarouselItem className='CarouselItem'
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={item.src}>
          <img src={item.src} alt={item.altText}/>
        </CarouselItem>
      );
    }
      
    });
  


  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === list.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? list.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };


  if(isok){
    return (
        <Carousel className='CarouselSlide AllCarusel'
          activeIndex={activeIndex}
          keyboard={false}
          pause={false}
          ride="carousel"
          next={next}
          previous={previous}>
          <CarouselIndicators
            items={list}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}/>
           {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous} />
          <CarouselControl
            direction="next"
            irectionText="Next"
            onClickHandler={next}/>
        </Carousel>
    );
  }
  else{
    return (
      <Carousel className='carousel-fade MidCarousel AllCarusel'
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      {...list}>
     

       {slides}
     
      </Carousel>     
    );
  }
}



export default Slider;