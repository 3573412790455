/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'

export default function Navbar() {
  return (

    <nav className="navbar navbar-expand-lg C-Secondary">
        <div className="container-fluid ">
            <p className="navbar-brand ms-4 AmoreTxt">AMORE</p>
            <button className=" bg-light C-Base navbar-toggler border-white m-2" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <section className="d-flex align-items-center border-white  ">
                    <span className="navbar-toggler-icon"></span>
                </section>
            </button>
            <div className="collapse navbar-collapse justify-content-end ms-4" id="navbarSupportedContent">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link  text-light" href="tel:+994777309060" target="_blank" rel="noopener noreferrer" >ƏLAQƏ</a>
                    </li>
                    <li className="nav-item nav-insta">
                        <a id='instagram-text'  href="https://www.instagram.com/amoremebel.az/" target="_blank" rel="noopener noreferrer">INSTAGRAM</a>
                        <a className="insta_icon fa-brands fa-instagram fa-flip fa-xl" href="https://www.instagram.com/amoremebel.az/" target="_blank" rel="noopener noreferrer" >
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

  )
}
