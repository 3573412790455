import React from 'react'
import Slider from './Slider'
import MainSlider from './MainSlider';
const items = [
    {
      src: 'https://res.cloudinary.com/dbjqszsb4/image/upload/v1698883355/Amore/Slider2/_c1534101-5113-4696-9359-a60444c600da_o848vh.jpg',
      altText: '',
      caption: '',
      key: 1,
    },
    {
      src: 'https://res.cloudinary.com/dbjqszsb4/image/upload/v1697817843/Amore/Slider/kgzpx29it4tnqop7h76d.jpg',
      altText: '',
      caption: '',
      key: 2,
    },
    {
      src: 'https://res.cloudinary.com/dbjqszsb4/image/upload/v1697817843/Amore/Slider/cdvmwodtcgnstub0jvvu.jpg',
      altText: '',
      caption: '',
      key: 3,
    },
    {
      src: 'https://res.cloudinary.com/dbjqszsb4/image/upload/v1697817843/Amore/Slider/d1gowipcmv7qjl82y2ak.jpg',
      altText: '',
      caption: '',
      key: 4,
    },
];


const circleItems = [
  {
    src: 'https://res.cloudinary.com/dbjqszsb4/image/upload/v1698883993/Amore/circleimg/_832709ed-35eb-498b-9826-dfaa710f0c55_scwfp2.jpg',
    altText: '',
    caption: '',
    key: 1,
  },
  {
    src: 'https://res.cloudinary.com/dbjqszsb4/image/upload/v1698883993/Amore/circleimg/_6b560229-b55c-46c1-8d31-8885e1f8924a_nwjupw.jpg',
    altText: '',
    caption: '',
    key: 2,
  },
  {
    src: 'https://res.cloudinary.com/dbjqszsb4/image/upload/v1698883994/Amore/circleimg/_55e18368-d447-4440-b7cb-9dea81fbcd79_umz8gw.jpg',
    altText: '',
    caption: '',
    key: 3,
  },
  {
    src: 'https://res.cloudinary.com/dbjqszsb4/image/upload/v1698883993/Amore/circleimg/_90464905-bac1-4bfd-bc3a-1eee327d880b_utcexz.jpg',
    altText: '',
    caption: '',
    key: 4,
  },
];


const slider2Items = [
  {
    src: 'https://res.cloudinary.com/dbjqszsb4/image/upload/v1698883355/Amore/Slider2/_2654a003-7ce1-41f8-9902-ea55be5f3885_hgoxzs.jpg',
    altText: '',
    caption: '',
    key: 1,
  },
  {

    src: 'https://res.cloudinary.com/dbjqszsb4/image/upload/v1698883355/Amore/Slider2/_c8b4e4a0-6026-46ed-9ebe-fb7bea16089d_d5iiyf.jpg',
    altText: '',
    caption: '',
    key: 2,
  },
  {
    src: 'https://res.cloudinary.com/dbjqszsb4/image/upload/v1698883355/Amore/Slider2/_013b5820-48bd-4573-9527-49e9c3111946_azoidg.jpg',
    altText: '',
    caption: '',
    key: 3,
  },
  {
    src: 'https://res.cloudinary.com/dbjqszsb4/image/upload/v1698883355/Amore/Slider2/_b7d323bc-9091-4d28-8519-58aab7dbc279_cruiuf.jpg',
    altText: '',
    caption: '',
    key: 4,
  },
];




export default function Sliders() {
  return (
    <section>
        <section className='CarouselList animate__animated animate__slideInRight'>
          <Slider list={items}></Slider> 
          <Slider list={circleItems} isok={false} ></Slider> 
          <Slider list={slider2Items}></Slider> 
        </section>
        <section>
        <div className="d-flex bg-dark line_and_iconDiv">
          <div className="div1"></div>
          <div className='FontAwsomeSofaIconDiv animate__animated animate__heartBeat'  style={{ color:'white' ,margin: '0.5vi', fontSize: '3vh' }}>
              <i className="fa-sharp fa-solid fa-couch fa-fade"></i>
          </div>
          <div className="div1"></div>
        </div>
        
          <MainSlider className="animate__animated animate__slideInLeft"></MainSlider>
        </section>
    </section>
  )
}
