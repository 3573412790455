import React, { useEffect, useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';

const items = [
    {
      src: 'https://res.cloudinary.com/dbjqszsb4/image/upload/v1697994149/Amore/MainFolder/slider_foto_zb0y6f.png',
      key: 1,
    },
    {
      src: 'https://res.cloudinary.com/dbjqszsb4/image/upload/v1697994145/Amore/MainFolder/MainSlider3_m3dzgg.png',
      key: 2,
    },
    {
      src: 'https://res.cloudinary.com/dbjqszsb4/image/upload/v1698005669/Amore/MainFolder/MainSlider4_oii4ot.png',
      key: 3,
    },
    {
      src: 'https://res.cloudinary.com/dbjqszsb4/image/upload/v1698005672/Amore/MainFolder/MainSlider5_h9knht.png',
      key: 4,
    },
    {
      src: 'https://res.cloudinary.com/dbjqszsb4/image/upload/v1697994136/Amore/MainFolder/MainSlider2_vqk3oh.png',
      key: 5,
    }

];

function MainSlider(args) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
 
  useEffect(() => {
    const interval = setInterval(() => {
      if (!animating) {
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
      }
    }, 8000);

    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, animating, items.length]);

  
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem className='CaruselItem2'
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} />

            <CarouselCaption className='MainSlideTextBox'
                captionHeader="AMORE MEBEL"
            />

      </CarouselItem>
    );
  });

  return (
    <Carousel className='CarouselSlide2'
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      {...args}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
    </Carousel>
  );
}

export default MainSlider;