import React, { useEffect } from 'react'
// import Product from './Product'

function CarouselListAdd(){
  var myDiv = document.querySelector('.CarouselList');
  var carusElements = myDiv.querySelectorAll('.AllCarusel');

  for (var i = 0; i < carusElements.length; i++) {
    var newId = 'car' + ((i + 1).toString());
    carusElements[i].id = newId;
  }
}




// eslint-disable-next-line no-unused-vars
// const Products = [
//   {
//     name: "Dark",
//     image:  "https://www.domesca.com/hometrends/wp-content/uploads/furniture-in-black-and-dark-colors-appetite-1024x696.jpg",
//     price: 300,
//     discount: "15%",
//     count: 5
//   },
//   {
//     name: "Adidas ",
//     image: "https://static.footshop.com/86485/15252.jpg",
//     price: 500,
//     discount: "5%",
//     count: 10
//   },
//   {
//     name: "Jordan Dior",
//     image: "https://jingdaily.com/wp-content/uploads/2022/02/dior-air-jordan-1-analysis-luxury-streetwear-collaborations-1.jpg",
//     price: 2000,
//     discount: "5%",
//     count: 0
//   },
//   {
//     name: "Puma",
//     image: "https://images.unsplash.com/photo-1608231387042-66d1773070a5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHVtYSUyMHNob2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80",
//     price: 150,
//     discount: "25%",
//     count: 10
//   }
// ];

const Body = () => {

  useEffect(() => {
    setTimeout( CarouselListAdd,1100)
  }, []);

  // const renderList = () => {
  //   return Products.map((item, index) => (
  //     <Product key={index} product={item} />
  //   ));
  // };

  return <></>;
  //return <section className='Product_Section'>{renderList()}</section>;
};





export default Body;